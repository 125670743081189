
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import http from './api/api'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper)


Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(ElementUI);

let isLogin =sessionStorage.getItem('isLogin')||''
let custKey =sessionStorage.getItem('custKey')||0
let  user = sessionStorage.getItem('user')||0
let  session = sessionStorage.getItem('session')||0

store.commit('setLogin',isLogin)
store.commit('setCustKey',custKey)
store.commit('setUser',user)
store.commit('setSession',session)

Vue.prototype.$https =http
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
