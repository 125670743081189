<template>
  <div class="modelMenu">
    <router-link :to="'/home/'+$store.state.custKey" class="menu_text" exact @click.native="collapse1">
      <i class="el-icon-s-home"></i>
<!--      <span> Sign in</span>-->
      <span> My Preferences</span>
    </router-link>
    <!-- <router-link to="/MyBenefit" class="menu_text"   @click.native="collapse1" exact>
      <i class="el-icon-s-claim"></i>
      <span> My Benefit</span>
    </router-link> -->

<!--    <router-link to="/MyPreference" class="menu_text" exact  @click.native="collapse1">-->
<!--      <i class="el-icon-knife-fork"></i>-->
<!--      <span> My Preference</span>-->
<!--    </router-link>-->

    <router-link to="/RateMyMeals" class="menu_text" exact  @click.native="collapse1">
      <i class="el-icon-star-on"></i>
      <span> Rate My Meals</span>
    </router-link>

    <router-link to="/ModifyMyMeals" class="menu_text" exact  @click.native="collapse1">
      <i class="el-icon-edit-outline"></i>
      <span> Pick My Meals</span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["collapse"],
      data(){
      return {
       custKey:'',
      }
    },
  methods: {
    collapse1() {
      this.$emit("collapse");
    },


  },
      created(){
        // this.custKey =sessionStorage.getItem('custKey')||'undefined'
    }
};
</script>

<style lang="scss" scoped>
.modelMenu {
  display: flex;
  flex-direction: column;
  background: #a0c572;
  a {
    display: block;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(244, 249, 247);
    font-size: 16px;
    color: #fff;
    font-weight: 500;
  }
}
</style>
