<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>
<script>
import Main from "./views/main";
import Modelmenu from "./components/ModelMenu.vue";
import "@/elementStyle/fonts/style.css";
// import {getUrlParams} from './util/util'
export default {
  components: {
    Main,
    Modelmenu,
  },
  data() {
    return {
      drawer: false,
    };
  },

  methods: {
    collapse() {
      this.drawer = false;
    },
  },
};
</script>
<style>
@font-face {
  font-family: 'Gotham Light';
  src: url('@/static/font/Gotham-Light.otf') format('opentype');
}
</style>
<style lang="scss" scoped>
#app {
  font-family: 'Gotham Light', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  #head {

    z-index: 99;
    position: fixed;
    top: 0;
    background: #78964a;
    color: #ffffff;
    width: 100%;
    height: 3%;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
       display: none;
  }
  .model {
    display: none;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media screen and (max-width: 768px) {
  #app {
    #head {
      display: flex;
      box-sizing: border-box;
      height: 48px;
      padding: 0 5%;
      justify-content: space-between;
      img {
        width: 25px;
      }
    }
    .model {
      display: block;
    }
    .model_menu {
      width: 100%;
      background: #fff;
      position: fixed;
      top: 48px;
      z-index: 888;
      // margin-top: 48px;
    }
  }
}
</style>

