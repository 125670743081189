import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin:false,
    custKey:0,
    user: 0,
    session:0

  },
  getters: {
  },
  mutations: {
    setLogin(state,payload){
          state.isLogin=payload
    },
    setCustKey(state,payload){
          state.custKey=payload
    },

    setUser(state,payload){
      state.user= payload
    },

    setSession(state,payload){
      state.session= payload
    }

  },
  actions: {
  },
  modules: {
  }
})
