<template>
  <div class="home">
    <div class="left_menu">
      <div class="menu_head">
        <img :src="src" class="head_img" :class="{ collapsed: ac }" />
      </div>
      <div class="menu_abdomen">
        <router-link :to="'/sign/' + $store.state.custKey + '/#?' +$store.state.user" class="menu_text" exact>
          <i class="el-icon-user-solid"></i>
          <!--          <span> Sign in</span>-->
          <span>Sign In</span>
        </router-link>
        <router-link to="/home" class="menu_text" exact>
          <i class="el-icon-s-home"></i>
<!--          <span> Sign in</span>-->
          <span> My Preferences </span>
        </router-link>
        <!-- <router-link to="/MyBenefit" class="menu_text" exact>
          <i class="el-icon-s-claim"></i>
          <span> My Benefit</span>
        </router-link> -->

        <!-- <router-link to="/MyPreference" class="menu_text" exact v-if="$store.state.isLogin"> -->
        <!-- <i class="el-icon-knife-fork"></i> -->
        <!-- <span> My Preference</span> -->
        <!-- </router-link> -->
        <!--  -->
<!--        <router-link to="/MyPreference" class="menu_text" exact>-->
<!--          <i class="el-icon-knife-fork"></i>-->
<!--          <span> My Preference</span>-->
<!--        </router-link>-->
<!--        <router-link to="/MyPreference" class="menu_text" exact>-->
<!--          <i class="el-icon-knife-fork"></i>-->
<!--          <span> My Preference</span>-->
<!--        </router-link>-->

        <router-link to="/RateMyMeals" class="menu_text" exact>
          <i class="el-icon-star-on"></i>
          <span> Rate My Meals</span>
        </router-link>

        <router-link to="/ModifyMyMeals" class="menu_text" exact>
          <i class="el-icon-edit-outline"></i>
          <span> Pick My Meals</span>
        </router-link>
      </div>
      <div class="menu_tail"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  props: {
    src: {
      type: String,
      // required: true,
    },
    ac: {
      type: Boolean,
    },
  },
  data() {
    return {
      retract: "el-icon-s-fold",
      identifying: true,
      myId: "",
    };
  },
  created() {
    // this.getparamsId();
  },
  mounted() {},
  methods: {
    clickRoute(e) {
      this.$router.push(e);
    },

  },
};
</script>
<style lang="scss" scoped>
.router-link-active {
  color: #6aa66a !important;
  a,
  i {
    color: #6aa66a !important;
  }
}
.home {
  width: 100%;
  height: 100%;
}

.left_menu {
  width: 100%;
  height: 100%;
  // background: #a0c572;
  background: #fff;
  // background: #a0c572;
  overflow: hidden;
  transition: all 0.3s;

  a {
    // color: #fff;
    color: #333;
    i {
      color: #c9d0c9;
    }
  }

  .menu_head {
    padding-top: 20%;

    .head_img {
      width: 80%;
      // height: 70%;

      &.collapsed {
        width: 50%;
        height: 50%;
      }
    }
  }

  .menu_abdomen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60%;
    padding-top: 20%;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    // justify-content: center;
    align-items: center;

    .menu_text {
      text-align: left;
      width: 80%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      cursor: pointer;
      // line-height: 50px;

      i {
        font-size: 30px;
        margin-right: 5px;
      }
      span {
        line-height: 1;
      }
    }
  }

  .menu_tail {
  }
}

@media screen and (max-width: 1400px) and (min-width: 1024px) {
}
// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .left_menu {
    .menu_abdomen {
      .menu_text {
        // text-align: left;
        justify-content: center;
        font-size: 35px;
        span {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .left_menu {
    display: none;
  }
}
</style>
